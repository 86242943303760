// JavaScript Document

// Scripts written by Charles Aldarondo for Weblinx, Inc.

/* eslint-disable no-unused-vars */

const filter_values = function filter_values(id) {
    const value = jQuery("#search_" + id).val().toLowerCase();
    const classPrefix = id === "product_list" ? ".ms-selectable" : ".ms-selection";
    const classPostfix = id === "product_list" ? ".ms-elem-selectable" : ".ms-elem-selection.ms-selected";

    if (value) {
        jQuery(classPrefix + " " + classPostfix).each(function (index, item) {
            if (item.innerText && item.innerText.toLowerCase().includes(value) && ((id === "product_list" && !jQuery(item).hasClass("ms-selected")) || (id !== "product_list" && jQuery(item).hasClass("ms-selected")))) {
                jQuery(item).show();
            } else {
                jQuery(item).hide();
            }
        });
        jQuery(classPrefix + " .ms-optgroup-label").each(function (index, item) {
            if (jQuery(item).siblings(":visible").length) {
                jQuery(item).show();
            } else {
                jQuery(item).hide();
            }
        });
    } else {
        jQuery(classPrefix + " " + classPostfix).each(function (index, item) {
            if ((id === "product_list" && !jQuery(item).hasClass("ms-selected")) || (id !== "product_list" && jQuery(item).hasClass("ms-selected"))) {
                jQuery(item).show();
            }
        });
        jQuery(classPrefix + " .ms-optgroup-label").each(function (index, item) {
            if (jQuery(item).siblings(":visible").length) {
                jQuery(item).show();
            } else {
                jQuery(item).hide();
            }
        });
    }
};

const search_form = function search_form(id) {
    const label = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "Search products";

    let output = "";

    output += "<div class='content_search-form_container search-form_container' id='" + id + "'><div class='search-form'>";

    output += "<label class='search-form_label _visuallyhidden' for='search_" + id + "'>" + label + "</label>";
    output += "<input class='search-form_input' name='search_" + id + "' id='search_" + id + "' type='input' onkeyup=\"filter_values('" + id + "')\" />";
    output += "<button class='search-form_button' type='button'><i class='search-form_button_icon icon'><svg class='icon_svg' aria-hidden='true'><use xlink:href='#search'></use></svg></i><span class='_visuallyhidden'>Submit</span></button>";

    output += "</div></div>";

    return output;
};

function show_hide_state(selectObject) {
    const s_a = new Array();
    s_a[0]="";
    s_a[1]="Alabama|Alaska|Arizona|Arkansas|California|Colorado|Connecticut|Delaware|District of Columbia|Florida|Georgia|Hawaii|Idaho|Illinois|Indiana|Iowa|Kansas|Kentucky|Louisiana|Maine|Maryland|Massachusetts|Michigan|Minnesota|Mississippi|Missouri|Montana|Nebraska|Nevada|New Hampshire|New Jersey|New Mexico|New York|North Carolina|North Dakota|Ohio|Oklahoma|Oregon|Pennsylvania|Puerto Rico|Rhode Island|South Carolina|South Dakota|Tennessee|Texas|Utah|Vermont|Virginia|Washington|West Virginia|Wisconsin|Wyoming";
    s_a[2]="Guadalajara Jalisco";
    s_a[3]="Eastern Canada|Western Canada";
    s_a[4]="Africa|Albania|Algeria|Argentina|Armenia|Australia|Austria|Bangladesh|Belarus|Belgium|Bosnia & Herzegovina|Brazil|Bulgaria|Central America|Chile|China|Colombia|Costa Rica|Croatia|Cyprus|Czech Republic|Denmark|Dominican Republic|Ecuador|Egypt|Estonia|Europe|Finland|France|Germany|Greece|Guatemala|Honduras|Hong Kong|Hungary|Iceland|India|Indonesia|Ireland|Israel|Italy|Japan|Jamaica|Korea (South)|Latvia|Lebanon|Liechterstein|Lithuania|Luxembourg|Macau|Macedonia|Malaysia|Malta|Mexico|Middle East|Moldova|Morocco|Netherlands|New Zealand|Norway|Pacific Islands|Pakistan|Panama|Peru|Philippines|Poland|Portugal|Puerto Rico|Romania|Russia|Saudi Arabia|Singapore|Slovakia|Slovenia|South Africa|South America|Spain|Sweden|Switzerland|Taiwan|Thailand|Tunisia|Turkey|Ukraine|United Arab Emirates|United Kingdom|Uruguay|Venezuela|Vietnam|";

    const countryIndex = jQuery("#country").prop("selectedIndex");
    const option_str = document.getElementById("state");
    option_str.length = 0;
    option_str.options[0] = new Option("Select State/Country","");
    option_str.selectedIndex = 0;
    const state_arr = s_a[countryIndex].split("|");
    for (let i = 0; i < state_arr.length; i++) {
        option_str.options[option_str.length] = new Option(state_arr[i],state_arr[i]);
    }

    const disabled = (countryIndex === 0);
    jQuery("#state").prop("disabled", disabled).toggleClass("-disabled", disabled);
}

const optional_fields = function optional_fields() {
    let output = "";

    output += "<div class='content_callout callout'>";

    output += "<h6 class='callout_title title'>Optional:</h6>";
    output += "<input class='callout_input input' name='project_name' id='project_name' placeholder='Project name or reference to include on your catalog' type='text' />";

    const href = window.location.href.toLowerCase();
    if (href.indexOf("illinois-capacitor") > 0 || href.indexOf("cornell-dubilier") > 0) {
        output += "<p class='callout_text text'>If you’d like to include a listing of your local sales representatives and distributors please provide your location.</p>";

        output += "<div class='callout_row row -padded -tight'>";
        output += "<div class='col-12 col-xs-6'>";
        output += "<select class='callout_input input -select -arrow' name='country' id='country' onchange='show_hide_state(this)'>";
        output += "<option value=''>Country</option>";
        output += "<option>United States</option>";
        output += "<option>Mexico</option>";
        output += "<option>Canada</option>";
        output += "<option>International</option>";
        output += "</select>";
        output += "</div>";

        output += "<div class='col-12 col-xs-6'>";
        output += "<select class='callout_input input -select -arrow -disabled' name='state' id='state' disabled='disabled'>";
        output += "<option value=''>State/Country</option>";
        output += "</select>";
        output += "</div>";

        output += "</div>"; // .callout_row.row.-padded.-tight
    }

    output += "</div>"; // .content_callout.callout

    return output;
};

jQuery(".gform_button").hide();

function on_download_catalog() {
    window["gf_submitting_2"] = false;
    window["gf_submitting_3"] = false;
    window["gf_submitting_4"] = false;
    jQuery("#input_2_24").val(jQuery("#country").val());
    jQuery("#input_2_25").val(jQuery("#state").val());
    jQuery("#input_3_3").val(jQuery("#country").val());
    jQuery("#input_3_4").val(jQuery("#state").val());
    const products = jQuery("#input_2_3").val() || jQuery("#input_3_1").val() || jQuery("#input_4_1").val();
    jQuery("form").attr("target", "_blank");
    if (products) {
        jQuery(".form_title input").val(jQuery("#project_name").val() || "Custom Catalog");
        jQuery(".gform_button").trigger("click");
    } else {
        alert("At least 1 product required");
        return false;
    }
}

// initialize the multiselect
jQuery(".gfield_select").multiSelect({
    keepOrder: true,
    selectableOptgroup: true,
    afterInit: () => {
        const params={};
        window.location.search
            .replace(/[?&]+([^=&]+)=([^&]*)/gi, function (str,key,value) {
                params[key] = value;
            }
            );
        if (params.series) {
            const options = decodeURIComponent(params.series).split(",");
            jQuery(".gfield_select").multiSelect("select", options);
        }
    },
    afterSelect: function afterSelect(values) {
        const optgroup_values = jQuery(".gfield_select").val();

        for (let i = 0; i < values.length; i++) {
            if (values[i].match(/^(.*) jQuery/)) {
                values[i] = values[i].trim();
            } else {
                values[i] = values[i] + " ";
            }

            if (optgroup_values.indexOf(values[i]) === -1) {
                jQuery(".gfield_select").multiSelect("select", values[i]);
            }
        }
    },
    afterDeselect: function afterDeselect(values) {
        for (let i = 0; i < values.length; i++) {
            if (values[i].match(/^(.*) jQuery/)) {
                values[i] = values[i];
            } else {
                values[i] = values[i] + " ";
            }
        }

        jQuery(".gfield_select").multiSelect("deselect", values);
    },
    selectableHeader: "<h3 class='content_title title'>Product List</h3>" + search_form("product_list"),
    selectionHeader: "<h3 class='content_title title'>My Custom Catalog</h3>" + search_form("custom_catalog"),
    selectionFooter: optional_fields() + "<a class='content_button button -icon -download -big _nomargin' style='width:100%;' onClick=\"on_download_catalog()\">Download Custom Catalog</a>"
});

/* eslint-enable no-unused-vars */ 
